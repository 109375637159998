import React from 'react';
import { Typography, Link } from '@mui/material';

export default function Copyright() {
    return (
        <>
            <Typography variant="body2" color={"text.secondary"} align="center" sx={{pt: 8, pb: 4}}>
                {'© 2024 [Diego Graciano] '}
                <Link color="inherit" href={window.location}>
                    Aura Studio
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Typography variant="body2" color={"text.secondary"} align="center" sx={{pb: 4}}>
                <Link href={window.location + 'termsandconditions'}>
                    Términos y Condiciones
                </Link>
            </Typography>
            <Typography variant="body2" color={"text.secondary"} align="center" sx={{pb: 4}}>
                <Link href={window.location + 'privacypolicy'}>
                    Política de privacidad
                </Link>
            </Typography>
        </>
    );
}