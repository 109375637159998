import React, { useState } from "react";
import CrossfadeCarousel from '@notbaldrick/react-crossfade-carousel'
import styled from 'styled-components'

const StyContainer = styled.div`
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Carousel(props) {
    const [forceActiveImage, setForceActiveImage] = useState(-1)
    const [interval, setInterval] = useState(3000)
    const [transition, setTransition] = useState(2000)
    const [cycle, setCycle] = useState(true)

    return (
        <StyContainer id='carousel-container'>
            <CrossfadeCarousel
                forceActiveImage={forceActiveImage < 0 ? null : forceActiveImage}
                interval={interval}
                transition={transition}
                cycle={cycle}
                images={props.images}
            />
        </StyContainer>
    )
}