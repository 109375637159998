import * as icon from '@coreui/icons';

let months = [
    { displayName: 'Enero', value: 1 },
    { displayName: 'Febrero', value: 2 },
    { displayName: 'Marzo', value: 3 },
    { displayName: 'Abril', value: 4 },
    { displayName: 'Mayo', value: 5 },
    { displayName: 'Junio', value: 6 },
    { displayName: 'Julio', value: 7 },
    { displayName: 'Agosto', value: 8 },
    { displayName: 'Septiembre', value: 9 },
    { displayName: 'Octubre', value: 10 },
    { displayName: 'Noviembre', value: 11 },
    { displayName: 'Diciembre', value: 12 },
];

let shortMonths = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];

let statuses = [
    { displayName: 'Activa', value: 'scheduled'},
    { displayName: 'Cancelada', value: 'cancelled'},
    { displayName: 'Finalizada', value: 'finalized'},
];

let adminCategories = [
    { displayName: 'Usuarios', icon: icon.cilHappy, id: 'user', singular: 'usuario' },
    { displayName: 'Coaches', icon: icon.cilGroup, id: 'coach', singular: 'coach' },
    { displayName: 'Paquetes', icon: icon.cilLibrary, id: 'pack', singular: 'paquete' },
    { displayName: 'Ventas', icon: icon.cilDollar, id: 'sale', singular: 'venta' },
    { displayName: 'Clases', icon: icon.cilBook, id: 'schedule', singular: 'clase' }
];

let disciplines = [
    { displayName: 'Sculpt/Tone', value: 'sculpt' },
    { displayName: 'Pilates Reformer & Sculpt/Tone', value: 'pilates&sculpt' },
]

export default { months, adminCategories, statuses, shortMonths, disciplines }