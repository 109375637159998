import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme, Button, Box, Avatar } from "@mui/material";

export default function ScheduleCard (props) {
    const [coachName, setCoachName] = useState({name: '', lastName: ''});
    const [coachPicture, setCoachPicture] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        (props.logguedInUser) && checkIfisRegistered();
    }, [props.logguedInUser]);

    useEffect(() => {
        getCoachName(props.session.teacherID);
    }, []);

    useEffect(() => {
        getCoachName(props.session.teacherID);
    }, [props.coaches]);

    // Import theme
    const theme = useTheme();

    // Check if user is already registered in class
    const checkIfisRegistered = () => {
        let isRegistered = props.session.participantIDS.includes(props.logguedInUser._id);
        setIsRegistered(isRegistered);
    }

    // Get available spaces
    const getAvailability = () => {
        let availableSpaces = props.session.classCapacity - props.session.participantIDS.length;
        return availableSpaces; 
    }

    // Get status
    const getStatus = () => {
        let status = (props.session.status == 'scheduled') ? 'Activa' : (props.session.status == 'cancelled') ? 'Cancelada' : 'Finalizada';
        return status;
    }

    // Obtain coach name
    const getCoachName = (coachID) => {
        let coaches =  props.coaches;
        if (coaches.length > 0) {
            let coach = coaches.find(coach => coach._id == coachID);
            setCoachPicture(coach.pictureURL);
            setCoachName({name: coach.firstName, lastName: coach.lastName});
        }
    }

   // Calculate minutes duration of a class -- both variables are in ISO format
   const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const diff = Math.abs(end - start);
        const minutes = Math.ceil((diff/1000)/60);
        return `${minutes} min`;
    };

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertISODateToLocalDate(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // Return the component
    return (
        <Box
            sx={{
                backgroundColor: props.isDarkMode ? '#000' : '#FFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '30px',
            }}
        >
            <Button
                sx={{
                    width: '100%',
                    height: '100%',
                    textTransform: 'none',
                    p:0,
                    borderRadius: '30px',
                    backgroundColor: props.isDarkMode ? '#000' : '#FFF',
                    opacity: (props.session.status == 'cancelled' || props.session.status == 'completed' || getAvailability() == 0) ? 0.5 : 1 
                }}
                onClick={() => props.setSelectedClass(props.session)}
                disabled={props.session.status == 'cancelled' || props.session.status == 'finalized' || props.session.status == 'completed' || getAvailability() <= 0 || isRegistered}
            >
                    <Grid 
                        container 
                        direction='column' 
                        sx={{
                            backgroundColor: props.color,
                            borderRadius: '30px'
                        }}
                    >
                        <Grid item xs={8} sx={{width: '100%', pt: 0.75, pl: 0.75, pr: 0.75}}>
                            <Grid container direction='row'>
                                <Grid item xs textAlign={'initial'}>
                                    <Typography sx={{fontSize: 25, fontFamily: 'PangramBold', fontWeight: 'bold', color: '#000', opacity: 0.60}}>
                                        {props.session.classTitle}
                                    </Typography>
                                    <Typography sx={{fontSize: 15, fontFamily: 'PangramRegular', color: '#000', opacity: 0.60}}>
                                        {props.session.classDescription}
                                    </Typography>
                                </Grid>
                                <Grid item xs textAlign={'end'}>
                                    <Typography 
                                        sx={{
                                            color: '#000',
                                            opacity: 0.60,
                                            fontFamily: 'PangramBold',
                                            }}
                                    >{`${coachName.name} ${coachName.lastName}`}</Typography>
                                    {
                                        coachPicture ? 
                                            <img src={coachPicture} alt='coach' style={{width: 70, height: 70, borderRadius: '50%', objectFit: 'cover'}}/> : 
                                            <Avatar 
                                            sx={{ 
                                                bgcolor: '#000',
                                                opacity: 0.40, 
                                                display: 'inline-flex', 
                                                height: 70, 
                                                width: 70,
                                                fontSize: 45,
                                                fontFamily: 'Qairo',
                                                margin: 1
                                            }}>
                                                {String(coachName.name.charAt(0)).toLocaleUpperCase() + String(coachName.lastName.charAt(0)).toLocaleUpperCase()}
                                            </Avatar>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container direction='row'>
                                <Grid item xs>
                                    <Typography sx={{
                                            fontSize: 20, 
                                            fontFamily: 'PangramBold', 
                                            color: props.color, 
                                            opacity: 0.60, 
                                            borderRadius: '50px',
                                            padding: 0.5,
                                            backgroundColor: 'rgb(0, 0, 0, 0.7)',
                                            display: 'inline-block',
                                            lineHeight: 0.7
                                        }}
                                    >
                                        {getAvailability()}
                                    </Typography>
                                    <Typography sx={{fontSize: 15, fontFamily: 'PangramRegular', color: '#000', opacity: 0.60}}>
                                        {'Espacios disponibles'}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{
                                            fontSize: 20, 
                                            fontFamily: 'PangramBold', 
                                            color: props.color, 
                                            opacity: 0.60, 
                                            borderRadius: '50px',
                                            padding: 0.5,
                                            backgroundColor: 'rgb(0, 0, 0, 0.7)',
                                            display: 'inline-block',
                                            lineHeight: 0.7
                                        }}
                                    >
                                        {getStatus()}
                                    </Typography>
                                    <Typography sx={{fontSize: 15, fontFamily: 'PangramRegular', color: '#000', opacity: 0.60}}>
                                        {'Estatus'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} sx={{p:1, pt: 0, pb: 0}}>
                            <Grid container direction='row'>
                                <Grid item xs textAlign={'initial'}>
                                    <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                        {getFormattedTime(props.session.time)}
                                    </Typography>
                                    <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                        {'Inicio'}
                                    </Typography>
                                </Grid>
                                <Grid item xs textAlign={'center'} alignContent={'center'}>
                                    <Typography 
                                        sx={{
                                            fontSize: 20, 
                                            fontFamily: 'PangramBold', 
                                            color: props.color, 
                                            opacity: 0.60, 
                                            borderRadius: '50px',
                                            padding: 0.5,
                                            backgroundColor: 'rgb(0, 0, 0, 0.7)'
                                        }}>
                                        {calculateDuration(props.session.time, props.session.endTime)}
                                    </Typography>
                                </Grid>
                                <Grid item xs textAlign={'end'}>
                                    <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                        {getFormattedTime(props.session.endTime)}
                                    </Typography>
                                    <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                        {'Fin'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </Button>
        </Box>
    )
}