import React, { useEffect } from 'react';
import {
    Grid, 
    Typography, 
    useTheme,
    CircularProgress,
    Box,
    ToggleButtonGroup,
    toggleButtonGroupClasses,
    ToggleButton,
    styled,
    Divider
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import ScheduleCard from '../ScheduleCard/ScheduleCard';
import AuraLogo from '../../assets/images/aura_icon.svg'
import es from 'date-fns/locale/es';
import axios from 'axios';

export default function ScheduleCalendar(props) {
    const [date, setDate] = React.useState(new Date());
    const [availableClasses, setAvailableClasses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [classType, setClassType] = React.useState(2); // <--- 0 is for sculpt/tone, 1 is for pilates reformer & sculpt/tone, 2 is for all.
    const [filteredClasses, setFilteredClasses] = React.useState([]);

    // Get theme
    const theme = useTheme();

    // Hear for changes of date to retrieve classes of selected date
    useEffect(() => { 
        getDayClasses(date);
    }, [date]);

    // Hear for changes of classType to filter classes
    useEffect(() => {
        filterClasses(classType);
    }, [classType, availableClasses]);

    // Function to filter classes by type
    const filterClasses = (type) => {
        if (type === 2) {
            setFilteredClasses(availableClasses);
        } else if (type === 1) {
            setFilteredClasses(availableClasses.filter((classItem) => classItem.discipline.includes('sculpt_tone')));
        } else if (type === 0) {
            setFilteredClasses(availableClasses.filter((classItem) => classItem.discipline.includes('pilates_reformer')));
        }
    };

    // Convert Central Standard Time to UTC for saving in database
    const convertToUTCString = (date) => {
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(utcDate).toISOString();
    };

    // Custom ToggleButton
    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            margin: theme.spacing(0.3),
            border: 1,
            [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 1,
                borderRadius: 0,
            },
            [`&.${toggleButtonGroupClasses.selected}`]: {
                border: 1,
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Keep the same background color on hover
                },
            },
            '&:hover': {
                backgroundColor: 'unset', // Disable hover color changing
            },
        },
        [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
            {
                borderLeft: '1px solid transparent',
                color: theme.palette.text.primary,
            },
    }));

    // Function to retrieve classes of selected date
    const getDayClasses = async () => {
        setLoading(true);
        // Set time to 0 to avoid timezone issues
        date.setHours(0, 0, 0, 0);
        // Delete time from the newValue to avoid timezone issues
        let queryDate = convertToUTCString(new Date(date));
        // Search for classes of selected date
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/find`, { date: queryDate })
        .then((res) => {
            setAvailableClasses(res.data.result);
            setLoading(false);
        })
        .catch((err) => {   
            console.log(err);
            setLoading(false);
        });
    };


    // Return the component
    return (
        <>
            <Grid container direction='row'>
                <Grid item xs={{xs: 12, sm: 6}} sx={{width: {xs: '100%', sm: '50%'}}}>
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            label="Week picker"
                            showDaysOutsideCurrentMonth={true}
                            value={date}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                            sx={{backgroundColor: 'unset'}}
                            disablePast
                            inputFormat="'Week of' MMM d"
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={{xs: 12, sm: 6}} sx={{width: {xs: '100%', sm: '50%'}}}>
                    <Box
                        sx={{
                            m: 1
                        }}
                    >
                        <StyledToggleButtonGroup
                            value={classType}
                            exclusive
                            fullWidth
                            onChange={(event, newAlignment) => {
                                if (newAlignment !== null) {
                                    setClassType(newAlignment);
                                }
                            }}
                            aria-label="text alignment"
                            sx={{
                                margin: 0, 
                                backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[300] : theme.palette.grey[800], 
                                borderRadius: '15px !important'
                            }}
                        >
                            <ToggleButton value={0} sx={{borderRadius: '0px', textTransform: 'none', lineHeight: 1}}>Pilates Reformer</ToggleButton>
                            <Divider orientation="vertical" flexItem sx={{borderWidth: '1px', my: 1}}/>
                            <ToggleButton value={1} sx={{borderRadius: '0px', textTransform: 'none'}}>Sculpt/Tone</ToggleButton>
                            <Divider orientation="vertical" flexItem sx={{borderWidth: '1px', my: 1}}/>
                            <ToggleButton value={2} sx={{borderRadius: '0px', textTransform: 'none'}}>Todo</ToggleButton>
                        </StyledToggleButtonGroup>
                    </Box>
                    {loading 
                    ? 
                    <CircularProgress size={80} sx={{margin: 5}}/> 
                    : 
                        (filteredClasses.length > 0 && !loading) 
                        ?
                        <Grid container spacing={2}>
                            {filteredClasses.map((classItem, index) => (
                                <Grid item xs={12} key={classItem._id}>
                                    <img src={AuraLogo} alt="Aura Logo" style={{width: '15px', height: 'auto', margin: '10px'}}/>
                                    <ScheduleCard 
                                        session={classItem} 
                                        isDarkMode={props.isDarkMode}
                                        setSelectedClass={props.setSelectedClass}
                                        color={index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main}
                                        coaches={props.coaches}
                                        logguedInUser={props.logguedInUser}
                                    />
                                </Grid>
                            ))}
                        </Grid> 
                        :
                        <Typography variant="h6" align="center" sx={{m:2}}>
                            Parece que no hay clases para el día que seleccionaste 🤔, intenta con otro
                        </Typography>
                    }
                </Grid>
            </Grid>
        </>
    )
}
