import React, { useEffect, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import axios from 'axios';
import renderField from '../../helpers/RenderField';
import DialogSports from '../Dialog/Dialog';

export default function PersonalDetails (props) {
    const [errors, setErrors] = useState({});
    const [schema, setSchema] = useState(null);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState([]);
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [phoneToVerify, setPhoneToVerify] = useState('');

    useEffect(() => {
        getSchema();
        setFormData(props.logguedInUser);
        // Check if user hasn't yet verified phone, if so then display alert
        if ((props.logguedInUser) && !props.logguedInUser.phoneVerified) {
            setDialogProps(
                { 
                    title: 'Verifica tu número de teléfono 📱', 
                    content: 'Para recibir notificaciones de tus transacciones, necesitas verificar tu número de teléfono. ¿Deseas hacerlo ahora?',
                    leftButton: 'No',
                    rightButton: 'Sí',
                    open: true
                }
            );
            setPhoneToVerify(props.logguedInUser.phoneNumber);
        }
    }, [props.logguedInUser]);

    // handle dialog left button
    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        (phoneToVerify === '') && window.location.reload();
        setPhoneToVerify('');
    }

    // handle dialog right button
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        
        if (phoneToVerify === '') return;
        props.setPhoneToVerify(phoneToVerify);
        props.setView('verifyPhone');
    }

    // handle change form data values
    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    // Convert Central Standard Time to UTC for saving in database
    const convertToUTCString = (date) => {
        const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(utcDate).toISOString();
    }

    // Get view schema from DB
    const getSchema = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/getSchema`)
        .then((res) => {
            setSchema(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    // Handle autocomplete change
    const handleAutocompleteChange = (fieldKey, value) => {
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/findbyName`, { query: value })
        .then(response => {
            setOptions(response.data);
        })
        .catch(error => console.error(error));
    
        setFormData(prevState => ({
            ...prevState,
            [fieldKey]: value
        }));
    };

    // Excluded keys
    const excludedKeys = [
        '_id',  
        'role', 
        'createdAt', 
        'updatedAt', 
        '__v', 
        'creditsAll', 
        'creditsPilates', 
        'creditsSculpt', 
        'cart',
        'reservations',
        'type',
        'otp',
        'phoneVerified',
        'type',
        'pictureURL',
        'id'
    ];

    // Check if form data is valid
    const isValid = () => {
        let hasErrors = false;
        let errors = {};
        Object.entries(schema).forEach(field => {
            const fieldKey = field[0];
            const fieldValue = field[1];
            (fieldValue.subtype === 'number' && (formData[fieldKey] === '' || formData[fieldKey] === undefined)) && (formData[fieldKey] = 0);
            (fieldValue.subtype === 'boolean' && (formData[fieldKey] === '' || formData[fieldKey] === undefined)) && (formData[fieldKey] = false);
            if ((fieldValue.required && !String(formData[fieldKey])) || (fieldValue.required && formData[fieldKey] === undefined)) {
                hasErrors = true;
                errors[fieldKey] = 'Este campo es requerido';
            }
        });
        setErrors(errors);
        return !hasErrors;
    }

    // handle submit form
    const handleSubmit = async () => {
        if (!isValid()) {
            setDialogProps(
                { 
                    title: 'Alerta ⚠️', 
                    content: 'Por favor verifica que todos los campos tengan información y vuelve a intentarlo', 
                    rightButton: 'Ok', 
                    open: true
                }
            );
            return;
        }

        const formattedData = { ...formData };

        // Check for each key in formattedData if it is a date and convert the value of that key into mexico city time string
        Object.keys(formattedData).forEach(key => {
            if (key === 'time' || key === 'purchaseTime' || key === 'endTime' || key === 'date' || key === 'purchaseDate' || key === 'endDate') {
                formattedData[key] = convertToUTCString(new Date(formattedData[key]));
            }
        });

        // Request to add or edit item
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, formattedData)
            .then((res) => {
                if (res.status == 200 && res.data.success == true) {
                    setDialogProps(
                        { 
                            title: 'Información ❗️', 
                            content: `Los detalles de tu usuario han sido actualizados correctamente.`, 
                            leftButton: 'Ok', 
                            rightButton: '', 
                            open: true
                        }
                    );
                } else {
                    setDialogProps(
                        { 
                            title: 'Alerta ⚠️', 
                            content: `Lo sentimos, no se han podido actualizar tus datos correctamente, por favor contacta a soporte.`, 
                            leftButton: 'Ok', 
                            rightButton: '', 
                            open: true
                        }
                    );
                }
            })
            .catch((err) => {
                setDialogProps(
                    { 
                        title: 'Alerta ⚠️', 
                        content: `Lo sentimos, no se han podido actualizar tus datos correctamente, por favor contacta a soporte.`, 
                        leftButton: 'Ok', 
                        rightButton: '', 
                        open: true
                    }
                );
            });
    }

    return (
        <Grid container direction='row'>
            <DialogSports 
                dialogProps={dialogProps} 
                setDialogProps={setDialogProps} 
                handleDialogLeftButton={handleDialogLeftButton} 
                handleDialogRightButton={handleDialogRightButton}
            />
            <Grid container sx={{m:1}} spacing={1}>
                {(schema && Object.entries(schema).length > 0) &&
                    (Object.entries(schema)) && Object.entries(schema).map(field => (
                        renderField(field, formData, handleChange, errors, handleAutocompleteChange, options, excludedKeys, props.coaches)
                    )
                )}
            </Grid>
            <Grid item xs={12} sx={{m:1}}>
                <Button
                    variant='contained'
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        color: '#fff'
                    }}
                    onClick={() => handleSubmit()}
                >
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}