import React, { useState, useEffect } from 'react';
import { 
    Grid, 
    Typography, 
    Button,
    Chip,
    Fab,
    colors,
    Snackbar
} from '@mui/material';
import RegisterToClassModal from './RegisterToClassModal';
import MyClasses from './MyClasses';
import Calendar from './Calendar';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import DialogSports from '../Dialog/Dialog';

export default function Schedules(props) {
    const [schedulesView, setSchedulesView] = useState('myClasses');
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });

    // View change handler
    const handleViewChange = (view) => {
        setSchedulesView(view);
    }

    // Handle Left button of dialog
    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
    }

    // Handle Right button of dialog
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
    }

    // Handle add new class
    const handleNewClass = () => {
        setSchedulesView('addNewClass');
    }

    return (
        <>
            <Grid container direction='column'>
                {/* View selector */}
                <Grid item xs={1} textAlign={'initial'} sx={{maxHeight: '50px', backgroundColor: props.darkMode ? colors.grey[10] : colors.grey[100]}}>
                    <Grid container direction='row'>
                        <Grid item xs={8} textAlign={'initial'}>
                            <Fab 
                                color={schedulesView === 'myClasses' ? 'primary' : 'secondary'} 
                                variant='extended' 
                                sx={{
                                    textTransform: 'none', 
                                    m:1,
                                    color: schedulesView === 'myClasses' ? '#FFF' : '#000'
                                }}
                                onClick={() => handleViewChange('myClasses')}
                            >
                                Hoy
                            </Fab>
                            <Fab 
                                color={schedulesView === 'schedule' ? 'primary' : 'secondary'}
                                variant='extended' 
                                sx={{
                                    textTransform: 'none',
                                    color: schedulesView === 'schedule' ? '#FFF' : '#000'
                                }}
                                onClick={() => handleViewChange('schedule')}
                            >
                                Mi Calendario
                            </Fab>
                        </Grid>
                        <Grid item xs={4} textAlign={'end'}>
                            <Fab 
                                color='secondary'
                                variant='extended' 
                                sx={{m: 1}}
                                onClick={() => handleViewChange('addNewClass')}
                            >
                                <CIcon icon={icon.cilPlus} style={{height: '17px'}}/>
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
                {/* View render */}
                <Grid item xs={10} sx={{backgroundColor: props.darkMode ? colors.grey[10] : colors.grey[100]}}>
                    {
                    schedulesView === 'myClasses' ?
                        <MyClasses 
                            schedulesView={schedulesView} 
                            logguedInUser={props.logguedInUser} 
                            handleNewClass={handleNewClass}
                            coaches={props.coaches}
                        />
                    :
                    schedulesView === 'schedule' ?
                        <Calendar 
                            schedulesView={schedulesView} 
                            logguedInUser={props.logguedInUser} 
                            coaches={props.coaches}
                            refreshUserData={props.refreshUserData}
                        />
                    :
                    schedulesView === 'addNewClass' ?
                        <RegisterToClassModal 
                            schedulesView={schedulesView} 
                            setSchedulesView={setSchedulesView} 
                            logguedInUser={props.logguedInUser}
                            setLogguedInUser={props.setLogguedInUser}
                            coaches={props.coaches}
                            />
                    : ''
                    }
                </Grid>
            </Grid>
        </>
    )
}
