import React, { useRef, useState, useEffect } from 'react';
import { 
    Grid,
    Button,
    TextField,
    useTheme
} from '@mui/material';
import axios from 'axios';

export default function CodeVerification ({ callback, reset, isLoading, phoneToVerify }) {
    const [code, setCode] = useState('');
    const theme = useTheme();

    // Request send SMS
    let sendOTP = async (action) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/aws/sendOTP`, { phoneNumber: phoneToVerify })
            .then((res) => {
                console.log('OTP request sent');
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Refs to control each digit input element
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

     // Reset all inputs and clear state
    const resetCode = () => {
        inputRefs.forEach(ref => {
            ref.current.querySelector('input').value = '';
        });
        inputRefs[0].current.querySelector('input').focus();
        setCode('');
    }

    // Call our callback when code = 6 chars
    useEffect(() => {
        if (code.length === 6) {
            if (typeof callback === 'function') callback(code);
            resetCode();
        }
    }, [code]); //eslint-disable-line

    // Listen for external reset toggle
    useEffect(() => {
        resetCode();
    }, [reset]); //eslint-disable-line

    // Handle input
    const handleInput = (e, index) => {
        const input = e.target;
        const previousInput = inputRefs[index - 1];
        const nextInput = inputRefs[index + 1];

        // Update code state with single digit
        const newCode = [...code];
        // Convert lowercase letters to uppercase
        if (/^[a-z]+$/.test(input.value)) {
            const uc = input.value.toUpperCase();
            newCode[index] = uc;
            inputRefs[index].current.value = uc;
        } else {
            newCode[index] = input.value;
        }
        setCode(newCode.join(''));

        input.select();

        if (input.value === '') {
            // If the value is deleted, select previous input, if exists
            if (previousInput) {
                previousInput.current.querySelector('input').focus();
            }
        } else if (nextInput) {
            // Select next input on entry, if exists
            nextInput.current.querySelector('input').select();
        }
    }

    // Select the contents on focus
    const handleFocus = (e) => {
        e.target.select();
    }

    // Handle backspace key
    const handleKeyDown = (e, index) => {
        const input = e.target;
        const previousInput = inputRefs[index - 1];
        const nextInput = inputRefs[index + 1];

        if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
            e.preventDefault();
            setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
            if (previousInput) {
                previousInput.current.querySelector('input').focus();
            }
        }
    }

    // Capture pasted characters
    const handlePaste = (e) => {
        const pastedCode = e.clipboardData.getData('text');
        if (pastedCode.length === 6) {
            setCode(pastedCode);
            inputRefs.forEach((inputRef, index) => {
                inputRef.current.value = pastedCode.charAt(index);
            });
        }
    };

    // Clear button deletes all inputs and selects the first input for entry
    const ClearButton = () => {
        return (
            <Button
                onClick={resetCode}
                sx={{textTransform: 'none', mt: 1}}
                disabled={code.length === 0}
                fullWidth
            >
                Reset
            </Button>
        )
    }

    // Re-send OTP
    const ResendButton = () => {
        const [isResending, setIsResending] = useState(false);
        const [resendCount, setResendCount] = useState(0);
        const [seconds, setSeconds] = useState(0);
        const milliseconds = 60000;

        const handleResendClick = () => {
            if (!isResending && resendCount < 2) { 
                setIsResending(true);
                sendOTP().then(() => {
                    setTimeout(() => {
                        setIsResending(false);
                        setResendCount(prevCount => prevCount + 1);
                    }, 60000); // 5 seconds timeout
                });
            }
        };

        useEffect(() => {
            if (isResending) {
                setSeconds(milliseconds / 1000);
                const timer = setInterval(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
                return () => clearInterval(timer);
            }
        }, [isResending]);

        return (
            <Button
                onClick={handleResendClick}
                sx={{
                    textTransform: 'none',
                    mt:1,
                    color: resendCount === 0 ? '#FFF' : theme.palette.primary.main,
                    borderRadius: '30px'
                }}
                disabled={isResending || resendCount >= 2}
                fullWidth
                variant={resendCount === 0 ? 'contained' : 'text'}
            >
                {
                    (isResending === false && resendCount === 0) ?  
                        'Enviar código' : 
                    (isResending === false && resendCount > 0) ?    
                        'Reenviar código' : 
                    (isResending === true) ?
                        `Reenviar código en ${seconds} segundos`: 
                        null
                }
            </Button>
        )
    }

    return (
        <>
            <Grid container direction={'row'} spacing={1}>
                {[0, 1, 2, 3, 4, 5].map((index) => (
                    <Grid item xs>
                        <TextField
                        className="text-2xl bg-gray-800 w-10 flex p-2 text-center"
                        key={index}
                        type="text"
                        maxLength={1}
                        onChange={(e) => handleInput(e, index)}
                        ref={inputRefs[index]}
                        autoFocus={index === 0}
                        onFocus={handleFocus}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        disabled={isLoading}
                    />
                </Grid>
                ))}
            </Grid>
            <ClearButton />
            <ResendButton />
        </>
        
    );

}