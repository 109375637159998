import React from "react";
import { Button, Typography, Box } from "@mui/material";
import CIcon from "@coreui/icons-react";

export default function DashboardButton(props) {
    return (
        <Button
            variant='contained'
            onClick={() => props.setAdminView(props.id)}
        >
            <Box>
                <CIcon 
                    icon={props.icon} 
                    style={{height: '50px', width: '50px', margin: '30px', color: '#FFF'}}>    
                </CIcon>
                <Typography
                    style={{textTransform: 'none', margin: '10px', color: '#FFF'}}
                >
                    {props.displayName}
                </Typography>
            </Box>
        </Button>
    )
}