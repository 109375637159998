import React, {useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSports(props) {
    const [disabled, setDisabled] = useState(false);
    const handleClose = () => {
        props.setDialogProps(prevState => ({
            ...prevState,
            open: false
        }));
    };

    useEffect(() => {
        (props.dialogProps.open === true) && setDisabled(false);
    }, [props.dialogProps.open]);

    return (
        <>
            <Dialog
                open={props.dialogProps.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={
                    {
                        sx: {
                            borderRadius: '30px',
                        }
                    }
                }
            >
                <DialogTitle>{props.dialogProps.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {props.dialogProps.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* Left button */}
                    {(props.dialogProps.leftButton != '') && 
                        <Button 
                            onClick={props.handleDialogLeftButton}
                            disabled={disabled}
                            sx={{
                                textTransform: 'none',
                            }}
                        >{props.dialogProps.leftButton}</Button>}
                    {/* Right button */}
                    {(props.dialogProps.rightButton != '') && 
                        <Button 
                            variant="contained" 
                            disabled={disabled}
                            onClick={
                                (e) => {
                                    setDisabled(true);
                                    props.handleDialogRightButton(e);
                                }
                            }
                            sx={{
                                color: '#FFF',
                                fontSize: '16px',
                                borderRadius: '20px',
                                textTransform: 'none',
                            }}
                        >{props.dialogProps.rightButton}</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}