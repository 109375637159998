import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Table, TableBody, TableRow, TableCell, Box, useTheme } from "@mui/material";
import { colors } from "../../themes/themeCons";
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import SwipeableViews from "react-swipeable-views";
// Categories component
import PersonalDetails from "./PersonalDetails";
import ClientService from "./ClientService";
import Settings from "./Settings";
import FAQ from "../../legal/FAQ";
import TermsAndConditions from "../../legal/TermsAndConditions";
import PrivacyPolicy from "../../legal/PrivacyPolicy";

export default function MyProfile(props) {
    const [pictureURL, setPictureURL] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);

    // Theme
    const theme = useTheme();

    // Icon style
    const iconStyle = { 
        color: colors.primary, 
        marginRight: "10px",
        height: '20px',
        width: '20px',
        display: 'inline-block'
    };

    // My Profile categories
    const categories = [
        { name: 'Detalles personales', icon: icon.cilUser, component: <PersonalDetails {...props} /> },
        // { name: 'Configuración', icon: icon.cilSettings, component: <Settings {...props} /> },
        { name: 'Servicio al cliente', icon: icon.cilEnvelopeLetter, component: <ClientService {...props} /> },
        { name: 'Términos y condiciones', icon: icon.cilInstitution, component: <TermsAndConditions backEnabled={false} /> },
        { name: 'Política de privacidad', icon: icon.cilHttps, component: <PrivacyPolicy backEnabled={false} /> },
        { name: 'FAQ', icon: icon.cilInfo, component: <FAQ {...props} /> }
    ];

    // Handle change
    const handleChange = (category) => {
        setSelectedCategory(category);
    };

    // My Profile menu component
    const MyProfileMenu = (props) => {
        return (
            <>
                <Grid container spacing={2} direction='column' id='myProfile'>
                    <Grid item xs={6} align="center" sx={{m:2}}>
                        {
                        /* Profile picture */
                        <ProfilePicture
                            setLogguedInUser={props.setLogguedInUser}
                            logguedInUser={props.logguedInUser}
                            userData={props.logguedInUser}
                            type="user"
                            postAction={() => window.location.reload()}
                        />
                        }
                        <Typography 
                            variant="h6"
                            sx={{
                                fontFamily: 'PangramBold',
                            }}
                        >{`${props.logguedInUser.firstName} ${props.logguedInUser.lastName}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {/* Table */}
                        <Table>
                            <TableBody>
                                {
                                    categories.map((category, index) => {
                                        let hasNotifications = false;

                                        // Check if category has notifications
                                        if (category.name === 'Detalles personales') {
                                            hasNotifications = !props.logguedInUser.phoneVerified;
                                        }
                                        return (
                                            <TableRow key={index}>
                                                <TableCell sx={{p: 0}}>
                                                    <Button 
                                                        sx={{
                                                            width: '100%', 
                                                            height: '100%', 
                                                            justifyContent: 'left', 
                                                            color: theme.palette.text.primary, 
                                                            textTransform: 'none', 
                                                            m:0, 
                                                            p:1
                                                        }}
                                                        onClick={() => handleChange(category)}
                                                        >
                                                        <Box display="flex" alignItems="center" sx={{width: '100%'}}>
                                                            <Grid container direction={'row'}>
                                                                <Grid item xs={hasNotifications ? 10 : 11}>
                                                                    <Box display="flex" alignItems="center">
                                                                        <CIcon icon={category.icon} style={iconStyle}/>
                                                                        <Typography variant="body1">{category.name}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                {
                                                                    hasNotifications &&
                                                                    <Grid item xs={1}>
                                                                        <div style={{backgroundColor: 'red', width: '25px', height: '25px', color: '#FFF', borderRadius: '50%'}}>
                                                                            1
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                                <Grid item xs={1}>
                                                                    <CIcon icon={icon.cilArrowRight} style={{marginLeft: 'auto', width: '20px', height: '20px', color: colors.primary}}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </>
        );
    }

    // Swiped View component
    const SwipedView = (props) => {
        return (
            <>
                <Grid alignSelf={'flex-start'} sx={{m: 1}} textAlign={'left'}>
                    <Button onClick={() => setSelectedCategory(null)}>
                        <CIcon 
                            icon={icon.cilArrowLeft} 
                            className='text-primary' 
                            style={{height: '20px', marginRight: '20px'}}
                        />
                        <Typography sx={{textTransform: 'none'}}>Atrás</Typography>
                    </Button>
                </Grid>
                <>
                    {props.selectedCategory.component}
                </>
            </>
        );
    }

    // return
    return (
        <>
            <SwipeableViews 
                index={(selectedCategory) ? 1 : 0}
                containerStyle={{
                    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                }}
                onChangeIndex={(index) => {
                    if (index === 0) {
                        setSelectedCategory(null);
                    }
                }} 
                
            >
                <div>
                    {/* My Profile */}
                    <MyProfileMenu {...props}/>
                </div>
                    {/* Swiped View */}
                <div>
                    { selectedCategory && <SwipedView selectedCategory={selectedCategory}/>}
                </div>
            </SwipeableViews>
        </>
    );
}