import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import axios from 'axios';

// Components
import BackButton from '../BackButton/BackButton';
import consts from '../../consts/consts';
// Views
import Dashboard from './Dashboard';
import AdminTable from './AdminTable';

export default function Admin(props) {

    // Get all users and save them in local storage
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/getAllNames`)
            .then(response => {
                localStorage.setItem('users', JSON.stringify(response.data.result));
            })
            .catch(error => console.error(error));
    }, []);

    return(
        <>
            {
                (props.adminView == 'dashboard')
                ?
                    <Dashboard logguedInUser={props.logguedInUser} setAdminView={props.setAdminView}/>
                :
                    <Grid container direction='column' padding={1}>
                        <Grid xs={2} item>
                            <Grid container direction='row'>
                                <Grid xs item textAlign={'left'}>
                                    <BackButton title={'Dashboard'} onClick={() => props.setAdminView('dashboard')} />
                                </Grid>
                                <Grid xs item textAlign={'right'}>
                                    <Typography
                                        sx={{fontFamily: 'Qairo', fontSize: '20px'}}
                                    >
                                        {consts.adminCategories.find((e) => e.id == props.adminView)?.displayName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={8} item>
                            <AdminTable adminView={props.adminView} coaches={props.coaches}/>
                        </Grid>
                    </Grid>
            }
            
        </>
    )
}