import React from "react";
import { Button, Typography } from "@mui/material";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';

export default function BackButton(props) {
    return(
        <Button 
            onClick={() => props.onClick()}
            sx={{width: 'auto'}}
            >
            <CIcon 
                icon={icon.cilArrowLeft} 
                className='text-primary' 
                style={{height: '20px', marginRight: '20px'}}
            />
            <Typography sx={{textTransform: 'none'}}>{props.title}</Typography>
        </Button>
    )
}