import React, { useState, useEffect } from 'react';
// MUI Components
import { 
    Typography, 
    Link,
    Grow,
    Box,
    BottomNavigation,
    BottomNavigationAction,
    Paper,
    useTheme
} from '@mui/material';

// Custom Components
import Landing from '../components/Landing/Landing';
import Login from '../components/Login/Login';
import SignUp from '../components/Login/SignUp';
import AppTopBar from './AppTopBar/AppTopBar';
import Admin from './Admin/Admin';
import axios from 'axios';
import consts from '../consts/consts';
import TodayIcon from '@mui/icons-material/Today';
import WalletIcon from '@mui/icons-material/Wallet';
import AccountIcon from '@mui/icons-material/AccountCircle';
import MyWallet from './RegularUser/MyWallet';
import MyProfile from './MyProfile/MyProfile';
import VerifyPhone from './Login/VerifyPhone';

// Regular user components
import Schedules from './RegularUser/Schedules';

export default function Main(props) {
    let [logguedInUser, setLogguedInUser] = useState(null);
    let [admin, setAdmin] = useState(false);
    let [view, setView] = useState('landing');
    let [value, setValue] = useState(0);
    let [phoneToVerify, setPhoneToVerify] = useState('');
    let [coaches, setCoaches] = useState([]);
    let [mobile, setMobile] = useState(false);

    // Theme
    const theme = useTheme();

    // Hear for changes in view to handle bottom navigation bar
    useEffect(() => {
        (view === 'schedule') && setValue(0);
        (view === 'myWallet') && setValue(1);
        (view === 'myProfile') && setValue(2);
    }, [view]);

    // Hear for changes in bottom navigation bar to handle view changes
    useEffect(() => {
        (value === 0) && setView('schedule');
        (value === 1) && setView('myWallet');
        (value === 2) && setView('myProfile');
    }, [value]);

    useEffect(() => {
        checkIfUserIsLoggedIn();
        loadLocalCoaches();
        (checkIfIOS() && isInStandaloneMode()) ? setMobile(true) : setMobile(false);
    }, []);

    let loadLocalCoaches = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/coach/getAll`)
            .then((res) => {
                setCoaches(res.data.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    let checkIfUserIsLoggedIn = async () => {
        // Obtain logguedin user from local storage
        let loggedInUser = await localStorage.getItem('LoggedInUser');
        // Evaluate if user is loggued in
        if ((loggedInUser != '') && (loggedInUser != null)) {
            // There is logged in user, pull most recent data and set it to state
            await refreshUserData(loggedInUser);            

            // Evaluate if user is admin or regular user
            if (JSON.parse(loggedInUser).type === 'admin') {
                setAdmin(true);
                setView('dashboard');
            } else if (JSON.parse(loggedInUser).type === 'user') {
                setAdmin(false);
                if (localStorage.getItem('purchaseCompleted')) {
                    localStorage.removeItem('purchaseCompleted');
                    setView('myWallet');
                } else {
                    setView('schedule');
                }
            }
        } else {
            // User is not loggued in
            setAdmin(false);
            mobile ? setView('login') : setView('landing');
        }
    }

    // Check if we're in iOS and on Safari
    const checkIfIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Check if devide is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Obtain most recent user data from DB
    const refreshUserData = async (loggedInUser) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/findByEmail`, { "email" : JSON.parse(loggedInUser).email }) 
            .then((res) => {
                let retrievedUser = res.data.result[0];
                let encodedUser = JSON.stringify(retrievedUser);
                localStorage.setItem('LoggedInUser', encodedUser);
                setLogguedInUser(retrievedUser);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Only for Checkout modal - reload window and set view to my wallet
    const purchaseCompleted = async () => {
        // Set local storage variable to indicate we should go back to my wallet after window reload is completed
        localStorage.setItem('purchaseCompleted', true);
        // Reload window
        window.location.reload();
    }
    
    return (
        <>
            {/* App Top bar */}
            {
                (      view === 'landing' 
                    || view == 'dashboard' 
                    || view == 'home' 
                    || view == 'schedule'
                    || view == 'myWallet'
                    || view == 'myProfile'
                    || consts.adminCategories.some(category => category.id === view)
                ) &&
                <AppTopBar 
                    view={view}
                    setView={setView}
                    setLogguedInUser={setLogguedInUser} 
                    logguedInUser={logguedInUser}
                    admin={admin}
                />
            }
            {/* Views */}
            {
                (view == 'landing') ?
                <Landing 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    admin={admin}
                    coaches={coaches}
                />
                : (view == 'login') ? 
                <Login 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    setAdmin={setAdmin}
                />
                : (view == 'signup') ? 
                <SignUp 
                    logguedInUser={logguedInUser} 
                    setLogguedInUser={setLogguedInUser}
                    setView={setView}
                    view={view}
                    setAdmin={setAdmin}
                    setPhoneToVerify={setPhoneToVerify}
                    phoneToVerify={phoneToVerify}
                />
                : (view == 'verifyPhone') ?
                <VerifyPhone
                    logguedInUser={logguedInUser}
                    setView={setView}
                    view={view}
                    setLogguedInUser={setLogguedInUser}
                    phoneToVerify={phoneToVerify}
                    setPhoneToVerify={setPhoneToVerify}
                />
                : ((view == 'dashboard' || consts.adminCategories.some(category => category.id === view)) && admin) ?
                <Grow in={(view == 'dashboard' || consts.adminCategories.some(category => category.id === view))}>
                    <Box>
                        <Admin 
                            logguedInUser={logguedInUser} 
                            setAdminView={setView} 
                            adminView={view} 
                            coaches={coaches}
                        />
                    </Box>
                </Grow>
                : (view == 'schedule') ?
                <Grow in={(view == 'schedule')}>
                    <Box sx={{mb: 5}}>
                        <Schedules
                            logguedInUser={logguedInUser}
                            setView={setView}
                            setLogguedInUser={setLogguedInUser}
                            darkMode={props.darkMode}
                            setPhoneToVerify={setPhoneToVerify}
                            coaches={coaches}
                            refreshUserData={refreshUserData}
                        />
                    </Box>
                </Grow>
                : (view == 'myWallet') ?
                <Grow in={(view == 'myWallet')}>
                    <Box sx={{mb: 5}}>
                        <MyWallet
                            logguedInUser={logguedInUser}
                            setView={setView}
                            setLogguedInUser={setLogguedInUser}
                            darkMode={props.darkMode}
                            purchaseCompleted={purchaseCompleted}
                            loadStripe={props.loadStripe}
                        />
                    </Box>
                </Grow>
                : (view == 'myProfile') ?
                <Grow in={(view == 'myProfile')}>
                    <Box sx={{mb: 5}}>
                        <MyProfile 
                            setLogguedInUser={setLogguedInUser}
                            logguedInUser={logguedInUser}
                            coaches={props.coaches}
                            setPhoneToVerify={setPhoneToVerify}
                            setView={setView}
                        />
                    </Box>
                </Grow>
                :
                ''
            }
            {
                (logguedInUser && logguedInUser.type === 'user' && view != 'landing') 
                && 
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: props.darkMode ? '#000' : '#FFF'}} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        sx={{mb: {xs: 1.5, sm: 1.5, md: 1, lg: 0}}}
                        >
                        <BottomNavigationAction label="Mis Clases" icon={<TodayIcon />} />
                        <BottomNavigationAction label="Mi Cartera" icon={<WalletIcon />} />
                        <BottomNavigationAction label="Mi Perfil" icon={<AccountIcon />} />
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}