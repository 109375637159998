import React, { useState, useEffect} from 'react';
import { 
    Grow, 
    Button, 
    CssBaseline, 
    TextField, 
    FormControlLabel, 
    Link, 
    Grid, 
    Box, 
    Typography, 
    Container,
    Switch,
    IconButton,
    InputAdornment,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
    toggleButtonGroupClasses,
    Divider,
    styled
} from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import AuraLogo from '../../assets/images/full_logo.svg';
import axios from 'axios';
import DialogSports from '../Dialog/Dialog';
import InstallSnack from '../RegularUser/InstallSnack';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function Login(props) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [rememberme, setRememberme] = useState(false);
    const [showPassword, setShowPassword] = useState(false); 
    const [isloggingIn, setIsloggingIn] = useState(false);
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [showInstallMessage, setShowInstallMessage] = useState(false); 
    const theme = useTheme();
    const [loginMethod, setLoginMethod] = useState('phone');

    useEffect(() => {
        // Loaded, check if remember email
        doWeRemember();
    }, []);

    // Check if we're in iOS and on Safari
    const checkIfIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Check if devide is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Check if should display install message
    useEffect(() => {
        if (checkIfIOS() && !isInStandaloneMode()) {
            setShowInstallMessage(true);
        }
    }, []);

    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    };

    let doWeRemember = () => {
        let result = localStorage.getItem("email");
        if (result !=  null && result != '') {
            setEmail(result);
            setRememberme(true);
        }
    }

    let rememberMe = () => {
        if (!rememberme) {
            localStorage.setItem("email", email);
            setRememberme(true);
        } else {
            localStorage.setItem("email", "");
            setRememberme(false);
        }
    }

    let handleLogin = async () => {
        setIsloggingIn(true);
        let searchQuery = loginMethod === 'phone' ? { "phoneNumber" : `+52${phone}` } : { "email" : email };
        if ((email != '' || phone != '') && password != '') {
            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/${loginMethod === 'phone' ? 'findByPhone' : 'findByEmail'}`, searchQuery)
            .then((res) => {
                let retrievedUser = res.data.result[0];
                if (retrievedUser == undefined) {
                    setDialogProps({title: 'Alerta ⚠️', content: 'Usuario no registrado, por favor regístrate haciendo click en el botón "Registrate"', leftButton: 'Ok', rightButton: '', open: true});
                    setIsloggingIn(false);
                } else if (retrievedUser.password === password) {
                    let encodedUser = JSON.stringify(retrievedUser);
                    localStorage.setItem('LoggedInUser', encodedUser);
                    props.setLogguedInUser(retrievedUser);
                    retrievedUser.type === 'admin' && props.setAdmin(true);
                    retrievedUser.type === 'admin' ? props.setView('dashboard') : props.setView('schedule');
                    setIsloggingIn(false);
                } else {
                    setDialogProps({title: 'Alerta ⚠️', content: 'Usuario y/o contraseña incorrecta, si la haz olvidado toca el botón "Olvidaste tu contraseña?"', leftButton: 'Ok', rightButton: '', open: true});
                    setIsloggingIn(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsloggingIn(false);
            })
        } else {
            setDialogProps({title: 'Alerta ⚠️', content: 'Usuario y/o contraseña faltantes, por favor revisa tus datos', leftButton: 'Ok', rightButton: '', open: true});
            setIsloggingIn(false);
        }
    }

    // Custom ToggleButton
    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            margin: theme.spacing(0.3),
            border: 1,
            [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 1,
                borderRadius: 0,
            },
            [`&.${toggleButtonGroupClasses.selected}`]: {
                border: 1,
                borderRadius: 20,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main, // Keep the same background color on hover
                },
            },
            '&:hover': {
                backgroundColor: 'unset', // Disable hover color changing
            },
        },
        [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
            {
                borderLeft: '1px solid transparent',
                color: theme.palette.text.primary,
            },
    }));

return (
<ThemeProvider theme={theme}>
    <Grow in={(props.view == 'login')}>
        <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <InstallSnack open={showInstallMessage} setOpen={setShowInstallMessage}/>
                    <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogLeftButton={handleDialogLeftButton}/>
                    <Grid alignSelf={'flex-start'}>
                        <Button onClick={() => props.setView('landing')}>
                            <CIcon 
                                icon={icon.cilArrowLeft} 
                                className='text-primary' 
                                style={{height: '20px', marginRight: '20px'}}
                            />
                            <Typography sx={{textTransform: 'none'}}>Inicio</Typography>
                        </Button>
                    </Grid>
                    <img src={AuraLogo} style={{height: '100px', margin: '20px'}}></img>
                    <Typography component="h1" variant="h5" sx={{fontFamily: 'Qairo'}}>
                        Iniciar sesión
                    </Typography>
                    <StyledToggleButtonGroup
                        value={loginMethod}
                        exclusive
                        fullWidth
                        onChange={(event, newMethod) => {
                            if (newMethod !== null) {
                                setLoginMethod(newMethod);
                            }
                        }}
                        aria-label="text alignment"
                        sx={{
                            mt: 2, 
                            backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[300] : theme.palette.grey[800], 
                            borderRadius: '22px !important'
                        }}
                    >
                        <ToggleButton value={'phone'} sx={{borderRadius: '0px', textTransform: 'none', lineHeight: 1, p: 0}}>Teléfono</ToggleButton>
                        <Divider orientation="vertical" flexItem sx={{borderWidth: '1px', my: 1}}/>
                        <ToggleButton value={'email'} sx={{borderRadius: '0px', textTransform: 'none', p: 0}}>E-mail</ToggleButton>
                    </StyledToggleButtonGroup>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {
                            loginMethod === 'phone' 
                            ?
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Número a 10 dígitos"
                            name="phone"
                            autoComplete="phone"
                            autoFocus
                            inputProps={{ inputMode: 'numeric' }}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            />
                            :
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        }
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        />
                        <FormControlLabel
                            control={<Switch value='remember' checked={rememberme} color="primary" onChange={(e) => rememberMe(e.target.checked)}/>}
                            label="Recuérdame"
                        />
                        {isloggingIn 
                            ? 
                        <CircularProgress/> 
                            :
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ 
                                mt: 3, 
                                mb: 2,
                                textTransform: 'none',
                                p: 0.75,
                                borderRadius: '30px',
                                color: '#FFF',
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                            }}
                            onClick={() => handleLogin()}
                            >
                                Iniciar sesión
                            </Button>
                        }
                        
                    <Grid container>
                        <Grid item xs={6}>
                            <Link href="#" variant="body2" onClick={() => alert('Aún estamos trabajando en esta función, por favor pónte en contacto con nosotros y te proporcionaremos una contraseña temporal')}>
                                Olvidaste tu contraseña?
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Link href="#" variant="body2" onClick={() => props.setView('signup')}>
                            {"No tienes cuenta? Regístrate ☺️"}
                            </Link>
                        </Grid>
                    </Grid>
                    </Box>
                </Box>
        </Container>
    </Grow>
</ThemeProvider>
);
}