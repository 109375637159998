import React, { useEffect, useState } from 'react';
import { Snackbar, Backdrop, Grid, Typography, useTheme } from '@mui/material';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import shareIcon from '../../assets/images/share.svg';

export default function InstallSnack (props) {
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        (props.open) ? setBackdropOpen(true) : setBackdropOpen(false);
    }, [props.open]);

    // handle close
    const handleClose = () => {
        props.setOpen(false);
    }

    // theme
    const theme = useTheme();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
            onClick={handleClose}
        >
            <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={props.open}
            message='I love snacks'
            key={'bottom-center'}
            ContentProps={{
                sx: {
                    backgroundColor: '#FFF',
                    color: '#000',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 1,
                },
            }}
            sx={{
                backgroundColor: '#FFF',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                marginBottom: '20px',
                borderRadius: '10px',
                height: '100px',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '97px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '40px',
                    height: '20px',
                    background: '#FFF',
                    clipPath: 'polygon(50% 100%, 0% 0%, 100% 0%)'
                }
            }}
            >
                <Grid container direction='column'>
                    <Grid item xs={2} alignSelf={'center'} textAlign={'right'}>
                        <CIcon 
                            icon={icon.cilPlus} 
                            style={{
                                height: '30px', 
                                color: '#000', 
                                borderRadius: '0.5px',
                                borderStyle: 'solid',
                                borderColor: '#000',
                                borderRadius: '15%',
                            }}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='body1' sx={{textAlign: 'center', color: '#000', display: 'block'}}>
                            Instala Aura en tu iPhone; 
                        </Typography>
                        <Typography variant='body1' sx={{textAlign: 'center', color: '#000', display: 'contents'}}>
                            presiona el ícono 
                        </Typography>
                        <img 
                            src={shareIcon} 
                            style={{
                                width: '25px',
                                display: 'inline-block',
                                marginRight: '5px',
                                marginLeft: '5px'
                            }}
                        />
                        <Typography variant='body1' sx={{textAlign: 'center', color: '#000', display: 'contents'}}>
                            y selecciona
                        </Typography>
                        <Typography variant='body1' sx={{textAlign: 'center', color: '#000', display: 'block'}}>
                            'Agregar a inicio'
                        </Typography>
                    </Grid>
                </Grid>
            </Snackbar>
    </Backdrop>
    )
}