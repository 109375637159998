import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme, Button, Box, Modal, Backdrop, Fade, IconButton, Table, TableBody, TableCell, TableRow, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ScheduleCalendar from "../ScheduleCalendar/ScheduleCalendar";
import axios from 'axios';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from "../Dialog/Dialog";

export default function RegisterToClassModal(props) {
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [selectedClass, setSelectedClass] = useState(null);
    const [coaches, setCoaches] = useState([]);
    const [registering, setRegistering] = useState(false);

    // Get coaches from local storage
    useEffect(() => {
        setCoaches(props.coaches);
    }, [props.coaches]);

    // Hear for changes of selectedClass
    useEffect(() => {
        if (selectedClass) {
            let isAbleToRegister = checkDisciplineCredits('all');
            if (!isAbleToRegister) {
                setDialogProps({ 
                    title: 'Alerta ⚠️', 
                    content: 'Parece que no tienes créditos suficientes para unirte a una clase, por favor ve a la sección de créditos e inténtalo de nuevo.', 
                    leftButton: '', 
                    rightButton: 'Ok', 
                    open: true
                });
            }
        }
    }, [selectedClass]);

    // Check if user has sufficient discipline credits for the discipline of the selected class
    const checkDisciplineCredits = (discipline) => {
        let classDiscipline = discipline;
        if (classDiscipline === 'all' && props.logguedInUser.creditsAll > 0) {
            return true 
        } else if (classDiscipline === 'pilates_reformer'  && (props.logguedInUser.creditsPilates > 0 || props.logguedInUser.creditsAll > 0)) {
            return true
        } else if (classDiscipline === 'sculpt_tone' && (props.logguedInUser.creditsSculpt > 0 || props.logguedInUser.creditsAll > 0)) {
            return true
        } else {
            return false
        }
    }

    // Handle Left button of dialog
    const handleDialogRightButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        setSelectedClass(null);
        (registering) && props.setSchedulesView('myClasses');
    }

    // Handle modal close
    const handleClose = () => {
        props.setSchedulesView('myClasses');
    };

    // Update user reservations in DB
    const updateUser = async (userID, creditsToUse, newCredits) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/update`, { _id: userID, [creditsToUse.name]: newCredits })
            .then((res) => {
                console.log('Reservations updated in DB');
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Send confirmation SMS
    const sendConfirmationSMS = async (phoneNumber, message) => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/aws/sendSMS`, { phoneNumber: phoneNumber, message: message })
            .then((res) => {
                console.log('SMS sent');
            })
            .catch((err) => {
                console.error(err);
            }
        );
    }

    // Check if still there is space in the class
    const checkClassSpace = async (classID) => {
        let classData = await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/getByID`, { classID: classID })
            .then((res) => {
                return res.data.result[0];
            })
            .catch((err) => {
                console.error(err);
            });
        
        if (classData.participantIDS.length < classData.classCapacity) {
            return true;
        } else {
            return false;
        }
    }

    // Handle register to class
    const registerToClass = async (e) => {
        e.preventDefault();

        setRegistering(true);

        let userID = props.logguedInUser._id;
        let classID = selectedClass._id;
        let stillSpace = await checkClassSpace(classID);
        // Request to update class participants
        if (stillSpace) {
            await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/updateClassParticipants`, { classID: classID, newParticipant: userID })
                .then((res) => {
                    let creditsToUse = getCreditsToUse('all');
                    let newCredits = creditsToUse.value - 1;
                    let newReservations = props.logguedInUser.reservations.concat(classID);
                    // Update local storage
                    let updatedUser = props.logguedInUser;
                    updatedUser[creditsToUse.name] = newCredits;
                    updatedUser.reservations = newReservations;
                    localStorage.setItem('LoggedInUser', JSON.stringify(updatedUser));

                    // Update user database tables
                    updateUser(userID, creditsToUse, newCredits);

                    // Update expirations
                    updateExpirations();

                    // Send confirmation SMS - To be implemented in the future due to SMS costs

                    //let message = `[AURA] - ¡Hola! Te has registrado a la clase ${selectedClass.classTitle} el día ${formatDate(selectedClass.date)} a la(s) ${getFormattedTime(selectedClass.time)}. Nos vemos en la sesión.`;
                    //sendConfirmationSMS(props.logguedInUser.phoneNumber, message);

                    // Update state
                    props.setLogguedInUser(updatedUser);

                    // Set dialog
                    setDialogProps({ 
                        title: '¡Éxito! 🎉', 
                        content: 'Te has registrado a la clase exitosamente, te esperamos en la sesión.', 
                        leftButton: '', 
                        rightButton: 'Ok', 
                        open: true
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
            } else {
                setDialogProps({ 
                    title: 'Alerta ⚠️', 
                    content: 'Lo sentimos, la clase ya está llena, por favor selecciona otra clase.', 
                    leftButton: '', 
                    rightButton: 'Ok', 
                    open: true
                });
            }
    }

    // Update expirations
    const updateExpirations = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/expiration/update`, {
            purchaserID: props.logguedInUser._id,
        })
        .then((response) => {
            console.log('Expiration updated succesfully');
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Get coach name
    const getCoachName = (coachID) => {
        let coach = coaches.find(coach => coach._id === coachID);
        return `${coach.firstName} ${coach.lastName}`;
    }

    // Format date function
    const formatDate = (inputDate) => {
        // Parse the input date string to a Date object
        const date = new Date(inputDate);

        // Adjust the date to the local timezone
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());

        // Extract the day, month, and year
        const day = String(localDate.getDate()).padStart(2, '0'); // padStart ensures 2 digits
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // getMonth is 0-based
        const year = localDate.getFullYear();

        // Construct the output date string
        return `${day}/${month}/${year}`;
    }

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertISODateToLocalDate(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // Get credits to use
    const getCreditsToUse = (discipline) => {
        if (discipline === 'all') {
            return { name: 'creditsAll', value: props.logguedInUser.creditsAll};
        } else if (discipline === 'pilates_reformer') {
            if (props.logguedInUser.creditsPilates == 0) {
                return { name: 'creditsAll', value: props.logguedInUser.creditsAll};
            } else {
                return { name: 'creditsPilates', value: props.logguedInUser.creditsPilates};
            }
        } else if (discipline === 'sculpt_tone') {
            if (props.logguedInUser.creditsSculpt == 0) {
                return { name: 'creditsAll', value: props.logguedInUser.creditsAll};
            } else {
                return { name: 'creditsSculpt', value: props.logguedInUser.creditsSculpt};
            }
        }
    }
            
    return (
        <Modal
            open={props.schedulesView === 'addNewClass'}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.schedulesView === 'addNewClass'}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <DialogSports dialogProps={dialogProps} setDialogProps={setDialogProps} handleDialogRightButton={handleDialogRightButton}/>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">Únete a una clase</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={11}>
                            {
                                selectedClass === null 
                                ?
                                /* If no selectedClass, then show calendar to pick class */
                                <Box>
                                    <ScheduleCalendar setSelectedClass={setSelectedClass} coaches={props.coaches} logguedInUser={props.logguedInUser}/>
                                </Box>
                                :
                                /* If selectedClass, then show class pre-register page */
                                <Box>
                                    <Button onClick={() => setSelectedClass(null)}>
                                        <CIcon 
                                            icon={icon.cilArrowLeft} 
                                            className='text-primary' 
                                            style={{height: '20px', marginRight: '20px'}}
                                        />
                                        <Typography sx={{textTransform: 'none'}}>Calendario</Typography>
                                    </Button>
                                    <Grid container direction='column'>
                                        <Grid item xs={1} textAlign={'center'} sx={{m: 2}}>
                                            <Typography variant="h6">Pre-registro</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Título</TableCell>
                                                        <TableCell>{selectedClass.classTitle}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Coach</TableCell>
                                                        <TableCell>{getCoachName(selectedClass.teacherID)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Fecha</TableCell>
                                                        <TableCell>{formatDate(selectedClass.date)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Horario</TableCell>
                                                        <TableCell>{getFormattedTime(selectedClass.time)} - {getFormattedTime(selectedClass.endTime)}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Descripción</TableCell>
                                                        <TableCell>{selectedClass.classDescription}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item xs={1} textAlign={'center'} sx={{m:2}}>
                                            <Typography >{`Créditos disponibles: ${getCreditsToUse('all').value}`}</Typography>
                                            <Typography >{`Costo de la clase: 1 crédito`}</Typography>
                                            <Typography >{`Créditos después de registrarte: ${getCreditsToUse('all').value - 1}`}</Typography>
                                            <Typography >{`¿Deseas registrarte a esta clase?`}</Typography>
                                        </Grid>
                                        <Grid item xs={1} textAlign='center'>
                                            {
                                                registering ?
                                                <CircularProgress size={80} sx={{margin: 5}}/>
                                                :
                                                <Button 
                                                    disabled={getCreditsToUse('all').value == 0} 
                                                    onClick={(e) => registerToClass(e)} 
                                                    variant='contained'
                                                    sx={{
                                                        color: 'white',
                                                        textTransform: 'none',
                                                        p: 0.75,
                                                        pl: 2,
                                                        pr: 2,
                                                        borderRadius: '30px',
                                                    }}
                                                >Registrarme</Button>
                                            }
                                            
                                        </Grid>
                                        <Grid item xs={1} textAlign={'center'}>
                                            <Typography sx={{fontSize: '10px', m: 3}}>
                                                Al presionar el botón "REGISTRARME" aceptas que se descontará la cantidad de 1 crédito de tu cuenta, revisa nuestras políticas de uso para más información
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}