import React, { useState, useEffect, useCallback } from "react";
import { 
    Grid, 
    Typography, 
    Button, 
    Box, 
    Modal, 
    Backdrop, 
    Fade, 
    IconButton, 
    Table, 
    TableBody, 
    TableCell, 
    TableRow,
    CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from "../Dialog/Dialog";
import { colors } from "../../themes/themeCons";
import CheckoutForm from "./CheckoutForm";
// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

export default function CheckoutModal(props) {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [continuePurchase, setContinuePurchase] = useState(false);
    const [continueButtonPressed, setContinueButtonPressed] = useState(false);

    // Use Effect first start, obtain publishable key
    useEffect(() => {
        // Obtain publishable key
        getPublishableKey();
    }, []);

    // Obtain publishable key function
    const getPublishableKey = async () => {
        await axios.get(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/stripe/config`)
            .then((response) => {
                setStripePromise(loadStripe(response.data.publicKey));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // Handle continue purchase
    const handleContinuePurchase = async (e) => {
        setContinueButtonPressed(true);
        e.preventDefault();
        await createPaymentIntent();
    }

    // Create payment intent
    const createPaymentIntent = async () => {
        await axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/stripe/create-payment-intent`, {
            amount: props.selectedPack.priceMXN
        })
        .then((response) => {
            setClientSecret(response.data.clientSecret);
            setContinuePurchase(true);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Handle modal close
    const handleClose = () => {
        setContinueButtonPressed(false);
        setContinuePurchase(false);
        props.setSelectedPack(null);
    };

    // Return discipline name
    const getDisciplineName = (discipline) => {
        switch (discipline) {
            case 'all':
                return 'Todas las clases';
            case 'pilates_reformer':
                return 'Pilates Reformer';
            case 'sculpt_tone':
                return 'Sculpt & Tone';
            default:
                return '';
        }
    }


    // RETURN
            
    return (
        <Modal
            open={props.selectedPack}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={props.selectedPack}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    width: {xs: '100vw', sm: '90vw', md: '80vw'},
                    height: {xs: '100vh', sm: '90vh', md: '80vh'},
                    overflowY: 'auto'
                }}>
                    <Grid 
                        container 
                        spacing={0} 
                        direction='column'
                    >
                        {/* Title and close button */}
                        <Grid item xs={1}>
                            <Grid container direction={'row'}>
                                <Grid item xs>
                                    <Typography variant="h6">Quick top-up</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton variant="contained" onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Participants */}
                        <Grid item xs={11} sx={{m: 0.5, mt: 2}}>
                            {
                                props.selectedPack &&
                                <Grid container direction={'column'} spacing={2}>
                                    {/* Pack title and icon */}
                                    <Grid item xs>
                                        <Grid container direction='row'>
                                            <Grid item xs={4}>
                                                <Box
                                                    sx={{
                                                        backgroundImage: `linear-gradient(to right, ${colors.primary}, ${colors.tertiary})`,
                                                        width: '50px',
                                                        height: '50px',
                                                        alignContent: 'center',
                                                        textAlign: 'center',
                                                        borderRadius: '10px',
                                                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                                    }}
                                                >
                                                    <CIcon 
                                                        icon={icon.cilDiamond} 
                                                        className='text-primary' 
                                                        style={{height: '20px', color: '#000', opacity: 0.7}}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant="h6">{getDisciplineName(props.selectedPack.discipline)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* Pack details table */}
                                    <Grid item xs>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{p: 0.5}} align='left'>{'Clases'}</TableCell>
                                                    <TableCell sx={{p: 0.5}} align='right'>{props.selectedPack.classes}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{p: 0.5}} align='left'>{'Validez'}</TableCell>
                                                    <TableCell sx={{p: 0.5}} align='right'>{`${props.selectedPack.validityDays} días`}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    {/* Total price section */}
                                    <Grid item xs>
                                        <Typography>Total: </Typography>
                                        <Typography
                                            sx={{
                                                color: colors.primary,
                                                fontSize: '30px',
                                                fontFamily: 'PangramBold',
                                                mt: '10px',
                                                mb: '30px'
                                            }}
                                        >{`$${props.selectedPack.priceMXN} MXN`}</Typography>
                                    </Grid>
                                    {/* Insert card details section */}
                                    {
                                        !continuePurchase ?
                                        <Grid item xs>
                                            <Button 
                                                variant="contained" 
                                                onClick={(e) => handleContinuePurchase(e)}
                                                disabled={continueButtonPressed}
                                                sx={{
                                                    backgroundColor: colors.primary,
                                                    color: colors.white,
                                                    textTransform: 'none',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    fontFamily: 'PangramBold',
                                                    fontSize: '20px',
                                                    padding: '8px',
                                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                                }}
                                            >
                                                {
                                                    continueButtonPressed ?
                                                    <CircularProgress color="inherit" size={30} />
                                                    :
                                                    'Continuar al pago'
                                                }
                                            </Button>
                                        </Grid> 
                                        :
                                        <Grid item xs>
                                            { stripePromise && clientSecret && (
                                                <Elements stripe={stripePromise} options={{ clientSecret }}>
                                                    <CheckoutForm 
                                                        logguedInUser={props.logguedInUser}
                                                        setLogguedInUser={props.setLogguedInUser}
                                                        selectedPack={props.selectedPack}
                                                        setSelectedPack={props.setSelectedPack}
                                                        purchaseCompleted={props.purchaseCompleted}
                                                    />
                                                </Elements>
                                            )}
                                        </Grid>
                                    } 
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}