import React, { useEffect, useState } from "react";
import { Grid, Typography, useTheme, Button, Box } from "@mui/material";
import consts from "../../consts/consts";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DialogSports from '../Dialog/Dialog';
import axios from "axios";

export default function DayCard (props) {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [deletionMode, setDeletionMode] = useState(false);
    const [dialogProps, setDialogProps] = useState({ title: '', content: '', leftButton: '', rightButton: '', open: false });
    const [refundable, setRefundable] = useState(false);
    const [inDeletion, setInDeletion] = useState(false);

    const minSwipe = 100;
        
    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.touches[0].clientX);
    }

    const onTouchMove = (e) => setTouchEnd(e.touches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipe;
        const isRightSwipe = distance < -minSwipe;
        if (isLeftSwipe || isRightSwipe) {
            setDeletionMode(isLeftSwipe);
        }
    }

    // Handle Left button of dialog
    const handleDialogRightButton = (e) => {
        e.preventDefault();
        // Remove class from user
        axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/schedule/removeClassParticipants`, 
            { "removeParticipant" : props.logguedInUser._id, "classID" : props.classData._id })
        .then((res) => {
            // If refundable, refund user
            if (refundable) {
                // Restore credits in user
                axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/user/updateUserCredits`, 
                    { "ID" : props.logguedInUser._id, "operation" : 'add', "creditsType": 'creditsAll', "credits": 1 })
                .then((res) => {
                    // Restore credits in expirations
                    axios.post(`${process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_BLL_URL : ''}/expiration/restore`, 
                    { "purchaserID" : props.logguedInUser._id })
                    .then((res) => {
                        console.log('Successfully restored credit in expirations');
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        let stringUserData = JSON.stringify(props.logguedInUser);
                        props.refreshUserData(stringUserData);
                        props.handleTabChange();
                        // Scroll to top of window
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        // Scroll to tab
                        props.scrollToTab();
                        // Close dialog
                        setDialogProps(
                            { 
                                title: '', 
                                content: '', 
                                leftButton: '', 
                                rightButton: '', 
                                open: false,
                            }
                        );
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
                
            } else {
                let stringUserData = JSON.stringify(props.logguedInUser);
                props.refreshUserData(stringUserData);
                props.handleTabChange();
                // Scroll to top of window
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // Scroll to tab
                props.scrollToTab();
                // Close dialog
                setDialogProps(
                    { 
                        title: '', 
                        content: '', 
                        leftButton: '', 
                        rightButton: '', 
                        open: false,
                    }
                );
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }

    // Handle Left button of dialog
    const handleDialogLeftButton = () => {
        setDialogProps(
            { 
                title: '', 
                content: '', 
                leftButton: '', 
                rightButton: '', 
                open: false
            }
        );
        setInDeletion(false);
    }

    // Get status
    const getStatus = (rawStatus) => {
        let status =    (rawStatus == 'scheduled') ? 'Activa' : 
                        (rawStatus == 'cancelled') ? 'Cancelada' :
                        (rawStatus == 'finalized') ? 'Finalizada' : 
                        '';
        return status;
    }

    // Get coach name
    const getCoachName = (coachID) => {
        if (props.coaches.length !== 0) {
            let coach = props.coaches.find(coach => coach._id == coachID);
            return `${coach.firstName} ${coach.lastName}`;
        } else {
            return '';
        }
        
    }

    // Get day name
    const getDayName = (day) => {
        const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        return dayNames[day];
    };

    // Get month name
    const getMonth = (month) => {
        const monthNames = consts.shortMonths
        return monthNames[month];
    };

    // Convert ISO Date to local Date
    const convertISODateToLocalDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset());
        return localDate;
    }

    // Get AM or PM time
    const getFormattedTime = (time) => {
        time = convertISODateToLocalDate(new Date(time));
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // Get date day number
    const getDayNumber = (date) => {
        return new Date(date).getDate();
    }

    // Return the component
    return (
        <>
            <DialogSports 
                dialogProps={dialogProps} 
                setDialogProps={setDialogProps} 
                handleDialogRightButton={handleDialogRightButton} 
                handleDialogLeftButton={handleDialogLeftButton}
            />
            <Grid 
                container 
                direction='row' 
                sx={{
                    backgroundColor: props.color,
                    borderRadius: '30px',
                    p: 0.75,
                    marginLeft: deletionMode ? '-150px' : '0px',
                    // display: 'inline-block',
                    height: '170px',
                    transition: 'all 0.35s',
                    overflow: 'hidden',
                    zIndex: 1
                }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <Grid item xs={4} sx={{width: '100%'}}>
                    <Grid container direction='column' textAlign={'left'}>
                        <Grid item xs>
                            <Typography sx={{fontSize: 25, fontFamily: 'Qairo', fontWeight: 'bold', color: '#000', opacity: 0.60}}>
                                {getDayName(new Date(convertISODateToLocalDate(props.classData.date)).getDay())}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography sx={{fontSize: 50, lineHeight: 1, fontFamily: 'PangramBold', fontWeight: 'bold', color: '#000', opacity: 0.60}}>
                                {getDayNumber(props.classData.date)}
                            </Typography>
                            <Typography sx={{fontSize: 50, lineHeight: 1,fontFamily: 'PangramBold', fontWeight: 'bold', color: '#000', opacity: 0.60}}>
                                {getMonth(new Date(props.classData.date).getMonth())}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container direction='column' sx={{height: '100%'}}>
                        <Grid item xs textAlign={'initial'}>
                            <Typography sx={{fontSize: 20, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                {props.classData.classTitle}
                            </Typography>
                            <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                {getFormattedTime(props.classData.time)}
                            </Typography>
                            <Typography sx={{fontSize: 15, fontFamily: 'PangramBold', color: '#000', opacity: 0.60}}>
                                {`Coach: ${getCoachName(props.classData.teacherID)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs alignContent={'end'} textAlign={'right'}>
                            <Typography 
                                sx={{
                                    fontSize: 20, 
                                    fontFamily: 'PangramBold', 
                                    color: props.color, 
                                    opacity: 0.60, 
                                    borderRadius: '50px',
                                    padding: 0.5,
                                    backgroundColor: 'rgb(0, 0, 0, 0.7)',
                                    width: 'auto',
                                    display: 'inline-block'
                                }}>
                                {getStatus(props.classData.status)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                props.classData.status == 'scheduled' &&
                <Button
                    sx={{
                        width: deletionMode ? '140px' : '0px',
                        height: deletionMode ? '175px' : '0px',
                        padding: deletionMode ? 'unset' : '0px',
                        opacity: deletionMode ? 1 : 0,
                        borderRadius: '30px',
                        marginTop: '-195px',
                        marginLeft: {xs: '60%', sm: '75%', md: '85%', lg: '90%'},
                        backgroundColor: 'red',
                        display: 'inline-block',
                        transition: 'all 0.5s, opacity 1.5s',
                        zIndex: 0,
                        textTransform: 'none'
                    }}
                    disabled={inDeletion}
                    onClick={() => {
                        // Minutes difference limit
                        let maxMinutes = 720;

                        setInDeletion(true);
                        // Obtain date and time of class and current date and time
                        let classDay = new Date(props.classData.date);
                        let classDayTime = new Date(classDay.getFullYear(), classDay.getMonth(), classDay.getDate(), new Date(props.classData.time).getHours(), new Date(props.classData.time).getMinutes());
                        let currentDayTime = new Date();

                        // Define difference in milliseconds
                        let differenceMilliseconds = Math.abs(currentDayTime - classDayTime);
                        let differenceMinutes = Math.floor(differenceMilliseconds / (1000 * 60));

                        // Evaluate if class is refundable
                        (differenceMinutes > maxMinutes) ? setRefundable(true) : setRefundable(false);

                        // Set dialog
                        setDialogProps({
                            title: 'Cancelar clase', 
                            content: (differenceMinutes > maxMinutes) ?
                            '¿Estás segur@ que deseas cancelar esta clase? Se te reembolsará tu crédito a tu Aura card. 😊' :
                            'Lo sentimos, no puedes cancelar esta clase. Solo puedes cancelar clases con 12 horas de anticipación para recibir tu crédito de vuelta, si no puedes asistir no te preocupes, simplemente falta a la case, estamos trabajando para brindarte una mejor experiencia.',
                            leftButton: (differenceMinutes > maxMinutes) ? 'No' : 'Ok', 
                            rightButton: (differenceMinutes > maxMinutes) ? 'Si' : '', 
                            open: true
                        });
                    }}
                >
                    <CIcon 
                        icon={icon.cilXCircle} 
                        className='text-primary' 
                        style={{height: '50px', color: '#FFF'}}
                    />
                    <Typography sx={{color: '#FFF', fontFamily: 'PangramBold', fontSize: 20, padding: 1}}>Cancelar</Typography>
                </Button>
            }
            
        </>
    )
}